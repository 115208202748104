import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Payment from './pages/Payment';
import Failed from './pages/Failed';

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/:paymentId/:transactionId" element={<Payment />} />
      <Route path="/failed" element={<Failed />} />
      <Route path="/" element={<Failed />} />

        {/* <Route path="about" element={<About />} />
        <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
