import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { SiteContext } from "../../context";

export default function Note() {
  const { transactionDetail, setTransactionDetail } = useContext(SiteContext);

  const NoteText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
    color: "#fff",
  }));

  return (
    <>
      <NoteText
        color="text.secondary"
        sx={{ mt: { xs: 2, lg: 10 }, ml: 1, mr: 1, color: "#9B9B9B" }}
      >
        <Typography sx={{ color: { xs: "#111729", lg: "#fff", fontSize: 14 } }}>
          Note:{" "}
          <span style={{ color: "#9B9B9B" }}>
            {transactionDetail?.englishDescription ||
              "Please ensure prompt payment using the provided details to prevent any delays or associated consequences. Kindly complete all mandatory fields accurately. Failure to do so within 1 hour of the transaction may result in a zero payment being recorded. Please be advised that making false claims carries legal ramifications."}
          </span>
        </Typography>
      </NoteText>
      <NoteText
        color="text.secondary"
        sx={{ ml: 1, mr: 1, mt: 2, mb: 2, color: "#9B9B9B" }}
      >
        <Typography sx={{ color: { xs: "#111729", lg: "#fff", fontSize: 14 } }}>
          नोट:{" "}
          <span style={{ color: "#9B9B9B" }}>
            {transactionDetail?.hindiDescription ||
              "उपयोगकर्ताओं से निवेदन है कि किसी भी विलम्ब और अन्य परिणामों से बचने के लिए दिए गए विवरणों का उपयोग करके भुगतान करें। साथ ही, हम सभी अनिवार्य फ़ील्डों को सही ढंग से भरने का अनुरोध करते हैं। लेन-देन के एक घंटे के भीतर आवश्यक जानकारी प्रस्तुत न करने पर भुगतान शून्य माना जाएगा। गलत दावों से कानूनी कार्रवाई होगी।"}
          </span>
        </Typography>
      </NoteText>
    </>
  );
}
